import Hero from '../Components/Home/Hero'
import HeroText from '../Components/Home/HeroText'
import Wrapper from '../Components/Home/HomeWrapper'
import Languages from '../Components/Home/Languages'
import Skills from '../Components/Home/Skills'

function Home() {
  return (
    <>
      <Wrapper>
        <HeroText />
        <Skills />
        <Languages />
      </Wrapper>
    </>
  )
}
export default Home
