import React from 'react'
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter'
import { light } from 'react-syntax-highlighter/dist/esm/styles/prism'
import { languages } from 'react-syntax-highlighter/dist/esm/languages/prism/javascript'
import { motion } from 'framer-motion'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
// Define custom theme colors
const arduinoLightTheme = {
  'code[class*="language-"]': {
    color: '#000000', // Black
    background: '#FFFFFF', // White
    fontFamily: 'Cabin, sans-serif', // Font family
  },
  comment: {
    color: '#E96579', // Green
  },
  property: {
    color: '#3BAFA9',
  },
  string: {
    color: '#E96579', // Green
  },
  number: {
    color: '#E96579', // Maroon
  },
  // Define other token colors as needed
}
const codeSnippet = `import background from '../../assets/hero.png'
import styled from 'styled-components'

const Wrapper = styled(motion.div)\`
    .hero{
                background-image: url(\${background});
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;
            
    }
    .hero-text {
                flex-basis: 50%;
                height: 100%;
                padding: 3rem;
        }
\``

const HeroText = () => {
  const lines = codeSnippet.split('\n')
  return (
    <>
      <div className='hero-section'>
        <div className='hero-code'>
          {lines.map((line, index) => (
            <HeroWrapper
              key={index}
              initial={{ opacity: 0, x: -200 }}
              animate={{ opacity: 1, x: 0 }}
              transition={{ delay: index * 0.125 }}
            >
              <SyntaxHighlighter language='css' style={arduinoLightTheme}>
                {line}
              </SyntaxHighlighter>
            </HeroWrapper>
          ))}
        </div>
        <div className='hero'>
          <div className='hero-text'>
            <h1>
              Welcome!
              <br /> Discover
            </h1>
            <div className='btn-container'>
              <NavLink to='/projects' className='btn'>
                My Projects
              </NavLink>
              <NavLink to='/cv' className='btn'>
                My CV
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const HeroWrapper = styled(motion.div)``
export default HeroText
