import styled from 'styled-components'
import { motion } from 'framer-motion'
const Wrapper = styled(motion.div)`
  padding: 0rem;
  margin: 0;
  h1 {
    color: var(--primary-500);
  }
  .single-language {
    flex-basis: 33.33%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.3rem;
    .flag-box {
      height: 30px;
      display: flex;
      img {
        width: 100%;
        height: auto;
      }
    }
    p {
      font-weight: 900;
      margin: 1rem;
    }
  }
  @media screen and (max-width: 992px) {
    .section {
      padding: 1rem;
    }
    .single-language {
      flex-basis: 50%;
      font-size: 1.2rem;
      font-weight: 800;
    }
    .languages {
      flex-direction: column;
    }
    .language {
      flex-basis: 100%;
    }
  }
`

export default Wrapper
