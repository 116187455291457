import Wrapper from './LanguagesWrapper'
import frenchFlagImage from './../../assets/french.png'
import germanFlagImage from './../../assets/german.png'
import englishFlagImage from './../../assets/english.png'

const Languages = () => {
  return (
    <>
      <Wrapper
        className='section'
        initial='hidden'
        whileInView='visible'
        viewport={{ once: true }}
        transition={{ duration: 0.3 }}
        variants={{
          visible: { opacity: 1, x: 0 },
          hidden: { opacity: 0, x: -200 },
        }}
      >
        <div className='section'>
          <h1>Languages</h1>
          <div>
            <div className='single-language'>
              <div className='flag-box'>
                <img src={frenchFlagImage} />
              </div>
              <p>French : Native</p>
            </div>
            <div className='single-language'>
              <div className='flag-box'>
                <img src={germanFlagImage} />
              </div>
              <p>German : B2</p>
            </div>
            <div className='single-language'>
              <div className='flag-box'>
                <img src={englishFlagImage} />
              </div>
              <p>English : C1</p>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  )
}

export default Languages
