import Wrapper from '../Components/Projects/ProjectsWrapper'
import SingleProject from '../Components/Projects/SingleProject'
import { projects } from '../data'
function Projects() {
  return (
    <Wrapper className='section'>
      <div className='projects-section'>
        {projects.map((project, index) => {
          return <SingleProject project={project} key={index} index={index} />
        })}
      </div>
    </Wrapper>
  )
}
export default Projects
