import projectImage from '../../assets/project.png'
import { NavLink } from 'react-router-dom'
import MotionWrapper from './SingleProjectWrapper'
function SingleProject({ project, index }) {
  console.log(project)
  const { name, image, description, languages, github, live } = project
  return (
    <MotionWrapper
      className='box-container'
      initial='hidden'
      whileInView='visible'
      viewport={{ once: true }}
      transition={{ duration: 0.3 + 0.2 * (index % 2) }}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -200 },
      }}
    >
      <div className='box'>
        <div>
          <h3 className='project-name'>{name}</h3>
          <div className='img-box'>
            <img src={image} className='project-img' />
          </div>
          <div className='languages-container'>
            {languages &&
              languages.map((language, index) => {
                console.log(language)
                const { color, icon } = language
                return (
                  <div
                    className='language'
                    key={index}
                    style={{ color: `${color}` }}
                  >
                    {icon}
                  </div>
                )
              })}
          </div>
        </div>

        <div className='text-container'>
          <div>
            <p>{description}</p>
          </div>
        </div>
        <div className='btn-container'>
          <a href={live} className='btn' target='_blank'>
            View Live
          </a>
          <a href={github} className='btn' target='_blank'>
            GitHub
          </a>
        </div>
      </div>
    </MotionWrapper>
  )
}
export default SingleProject
