import styled from 'styled-components'

const Wrapper = styled.nav`
  .navbar-container {
    width: 100%;
    height: 5rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: var(--white);
    color: var(--primary-500);
    font-family: var(--coverFont);
    padding: 0 1rem;
    font-size: 2rem;
    font-weight: 800;
  }
  .burger {
    visibility: hidden;
  }
  .burger-content {
    flex-basis: 66.6%;
    display: flex;
    flex-direction: row;
  }
  .link {
    color: var(--primary-500);
    display: flex;
    align-items: center;
  }
  .navbar-row {
    display: flex;
    flex-direction: row;
  }
  .left {
    gap: 1rem;
    flex-basis: 33.33%;
  }
  .burger-btn {
  }
  .mid {
    gap: 1rem;
    justify-content: space-evenly;
    flex-basis: 50%;
    align-items: center;
  }
  .right {
    justify-content: flex-end;
    flex-basis: 50%;
    font-size: 2rem;
    display: flex;
  }
  .links-container {
    a {
      color: var(--primary-500);
    }
    display: flex;
    align-items: center;
    gap: 1.5rem;
    font-size: 2.5rem;
  }

  @media screen and (max-width: 992px) {
    .navbar-container {
      flex-direction: column;
      padding: 1rem 0.5rem 0.5rem 0.5rem;
      height: auto;
      transition: var(--transition-slow);
      font-size: 2rem;
    }

    .left {
      width: 100%;
      justify-content: space-between;
    }
    .burger-content {
      display: block;
      flex-basis: auto;
      height: 0px;
      overflow: hidden;
      flex-direction: column;
      width: 100%;
      transition: var(--transition-slow);
    }
    .mid {
      width: 100%;
      flex-direction: column;
      align-items: flex-start;
      gap: 10px;
    }
    .right {
      margin-top: 5px;
      width: 100%;
      justify-content: flex-start;
    }
    .show-menu {
      transform: rotate(180deg);
    }
    .burger {
      visibility: visible;
      transition: var(--transition-slow);
    }
    .show {
      padding-top: 10px;
      height: 10rem;
      visibility: visible;
      transition: var(--transition-slow);
    }
  }
`

export default Wrapper
