import styled from 'styled-components'

const Wrapper = styled.div`
  margin-top: 2rem;
  padding: 2rem 0 0 0;
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  justify-content: center;
  background-color: white;
  .btn-section {
    display: flex;
    gap: 10px;
  }
  .inactive {
    background-color: lightgray;
  }
  .cv-box {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 2rem 1rem;

    img {
      width: 100%;
      height: 100%;
      border-radius: 10px;
      overflow: hidden;
      border-style: solid;
      border-width: 1px;
      border-color: lightgray;
    }
  }
`

export default Wrapper
