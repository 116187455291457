import { useEffect, useState } from 'react'
import Wrapper from './CVWrapper'

import CvEnImage from './En/CV_MATAGNE_KEVIN.png'
import CvFrImage from './Fr/CV_MATAGNE_KEVIN.png'
const CV = () => {
  const [language, setLanguage] = useState('English')
  const [url, setURL] = useState(
    'https://drive.google.com/file/d/1y5lU_05tP7oKmu9b74IfVSkkDRHOuZRH/view?usp=sharing'
  )

  return (
    <Wrapper>
      <div className='parameters-bar'>
        <div className='btn-section'>
          <button
            className={language == 'English' ? 'btn' : 'inactive btn'}
            onClick={() => {
              setLanguage('English')
            }}
          >
            English
          </button>
          <button
            className={language == 'French' ? 'btn' : 'inactive btn'}
            onClick={() => {
              setLanguage('French')
            }}
          >
            French
          </button>
          <a
            className='btn'
            href={
              language === 'English'
                ? 'https://drive.google.com/file/d/1y5lU_05tP7oKmu9b74IfVSkkDRHOuZRH/view?usp=sharing'
                : 'https://drive.google.com/file/d/18SLnRwzxW1iGfPt62FhvtiiZxSB8nT5m/view?usp=sharing'
            }
            target='_blank'
          >
            Download CV
          </a>
        </div>
      </div>
      <div>
        <div className='cv-box'>
          <img src={language === 'English' ? CvEnImage : CvFrImage} />
        </div>
      </div>
    </Wrapper>
  )
}
const PDFViewer = () => {}
export default CV
