import {
  SiJavascript,
  SiReact,
  SiNodedotjs,
  SiExpress,
  SiHtml5,
  SiCss3,
  SiMysql,
  SiMongodb,
  SiTypescript,
  SiFirebase,
} from 'react-icons/si'
import { TbBrandReactNative } from 'react-icons/tb'

import workoutImage from './assets/project.png'
import fakeErpImage from './assets/FakeERP.png'
import projectAppImage from './assets/ProjectApp.png'
import fakeCommerceImage from './assets/FakeCommerce.png'

export const languages = [
  { name: 'Javascript', icon: <SiJavascript />, color: '#f1e05a' },
  { name: 'React', icon: <SiReact />, color: '#61DBFB' },
  { name: 'Typescript', icon: <SiTypescript />, color: '#2D79C7' },
  {
    name: 'HTML',
    icon: <SiHtml5 />,
    color: '#E34C26',
  },
  {
    name: 'CSS',
    icon: <SiCss3 />,
    color: '#2565AE',
  },
  {
    name: 'MySQL',
    icon: <SiMysql />,
    color: '#DB7532',
  },
  {
    name: 'NodeJS',
    icon: <SiNodedotjs />,
    color: '#3C873A',
  },
  {
    name: 'Express',
    icon: <SiExpress />,
    color: 'black',
  },

  { name: 'MongoDb', icon: <SiMongodb />, color: '#4FAA41' },
]

export const projects = [
  {
    name: 'FakeERP',
    image: fakeErpImage,
    description: `FakeERP is built on a powerful set of technologies, including MySQL, Node.js, TypeScript, and React. These technologies allow FakeERP to offer a seamless and user-friendly experience for businesses of all sizes. React's component-based architecture makes it easy to add new features and customize the ERP to meet the specific needs of each business. TypeScript helps to improve the quality of the code and prevent errors. As a result, FakeERP is a robust and flexible ERP solution that can be used by businesses of all sizes.
    `,
    languages: [
      {
        name: 'HTML',
        icon: <SiHtml5 />,
        color: '#E34C26',
      },
      {
        name: 'CSS',
        icon: <SiCss3 />,
        color: '#2565AE',
      },
      { name: 'Javascript', icon: <SiJavascript />, color: '#f1e05a' },
      { name: 'React', icon: <SiReact />, color: '#61DBFB' },
      { name: 'MySQL', icon: <SiMysql />, color: '#61DBFB' },
      {
        name: 'Express',
        icon: <SiExpress />,
        color: 'black',
      },

      {
        name: 'NodeJS',
        icon: <SiNodedotjs />,
        color: '#3C873A',
      },
    ],
    github: 'https://github.com/CodeGeno/FakeERP',
    live: 'https://FakeERP.site',
  },

  {
    name: 'FakeCommerce',
    image: fakeCommerceImage,
    description: `A full-stack e-commerce website built with React, Node.js, Express, and MongoDB is a powerful platform for online shopping.
React provides a dynamic and interactive user interface, while Node.js and Express handle the server-side functionality. MongoDB manages the website's data.
E-commerce websites built with these technologies are fast, reliable, and scalable. They can be used by businesses of all sizes to sell products and services online.
The e-commerce website also features a Stripe payment process.`,
    languages: [
      {
        name: 'HTML',
        icon: <SiHtml5 />,
        color: '#E34C26',
      },
      {
        name: 'CSS',
        icon: <SiCss3 />,
        color: '#2565AE',
      },
      { name: 'Javascript', icon: <SiJavascript />, color: '#f1e05a' },
      { name: 'React', icon: <SiReact />, color: '#61DBFB' },
      { name: 'MongoDb', icon: <SiMongodb />, color: '#4FAA41' },
      {
        name: 'NodeJS',
        icon: <SiNodedotjs />,
        color: '#3C873A',
      },
    ],
    github: 'https://github.com/CodeGeno/FakeCommerce',
    live: 'https://FakeCommerce.site',
  },
  {
    name: 'Project Management App',
    image: projectAppImage,
    description: `This project management web application, built with React, Firebase, and Firestore, is my first full-stack project and is designed to be mobile-friendly. It allows teams to efficiently collaborate and stay organized by providing users with the ability to create tasks, track their progress, and share pictures of the project in real-time.

Users have certain rights and privileges to ensure smooth collaboration and organization within their teams. Each user is granted individual access to the application, which allows them to create and manage their own projects.

The application is built on a robust technology stack that includes React, Firebase, and Firestore.`,
    languages: [
      {
        name: 'HTML',
        icon: <SiHtml5 />,
        color: '#E34C26',
      },
      {
        name: 'CSS',
        icon: <SiCss3 />,
        color: '#2565AE',
      },
      { name: 'Javascript', icon: <SiJavascript />, color: '#f1e05a' },
      { name: 'React', icon: <SiReact />, color: '#61DBFB' },
      {
        name: 'Firebase',
        icon: <SiFirebase />,
        color: '#FFCB2D',
      },
    ],
    github: 'https://github.com/CodeGeno/Project-Management-App',
    live: 'https://www.projectmanagementapp.site',
  },
  {
    name: 'Workout Progression',
    image: workoutImage,
    description: `Get fit with my web-based workout app built using React and Firebase. This app allows you to easily create a workout session, track your progress, and create a progressive overload in your workout.

Firebase's powerful database stores your workout data securely, so you can access it from anywhere. The app also manages the increase in training volume to create a progressive overload, so you can be sure that you're always challenging yourself and making progress.

Track your evolution over time to see how far you've come and stay motivated. With Firebase's powerful database, your data is always safe and secure.`,
    languages: [
      {
        name: 'HTML',
        icon: <SiHtml5 />,
        color: '#E34C26',
      },
      {
        name: 'CSS',
        icon: <SiCss3 />,
        color: '#2565AE',
      },
      { name: 'Javascript', icon: <SiJavascript />, color: '#f1e05a' },
      { name: 'React', icon: <SiReact />, color: '#61DBFB' },
      {
        name: 'Firebase',
        icon: <SiFirebase />,
        color: '#FFCB2D',
      },
    ],
    github: 'https://github.com/CodeGeno/Mobile-Workout-Application',
    live: 'https://www.gymplanner.site',
  },
  // {
  //   name: 'Tinder Like',
  //   image: workoutImage,
  //   description:
  //     'A dating app built with React Native and Firebase, designed to mimic the functionality of Tinder. Users can create profiles, swipe through potential matches, and message those they are interested in. The app utilizes Firebase for user authentication and database storage, allowing for quick and secure matchmaking. Users can also access additional features such as location tracking and real-time messaging. The app is easy to use and has a sleek, modern design.',
  //   languages: [
  //     { name: 'React Native', icon: <TbBrandReactNative />, color: '#61DBFB' },
  //     ,
  //     {
  //       name: 'Firebase',
  //       icon: <SiFirebase />,
  //       color: '#FFCB2D',
  //     },
  //   ],
  //   github: '',
  //   live: '',
  // },
]

export const nextProjects = [
  {
    name: 'Tinder Like',
    image: workoutImage,
    description:
      'A dating app built with React Native and Firebase, designed to mimic the functionality of Tinder. Users can create profiles, swipe through potential matches, and message those they are interested in. The app utilizes Firebase for user authentication and database storage, allowing for quick and secure matchmaking. Users can also access additional features such as location tracking and real-time messaging. The app is easy to use and has a sleek, modern design.',
    languages: [
      { name: 'React Native', icon: <TbBrandReactNative />, color: '#61DBFB' },
      ,
      {
        name: 'Firebase',
        icon: <SiFirebase />,
        color: '#FFCB2D',
      },
    ],
    github: '',
    live: '',
  },
]
