import styled from 'styled-components'
import { motion } from 'framer-motion'
const Wrapper = styled(motion.div)`
  margin: 3rem 0;
  display: flex;
  h1 {
    color: var(--primary-500);
  }
  h2 {
    color: var(--primary-400);
  }
  .languages-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    margin: 0 0 2rem 0;
  }

  .single-language {
    flex-basis: 33.33%;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 10px;
    font-size: 1.3rem;
    p {
      font-weight: 900;
    }
  }
  .languages {
    display: flex;
    width: 100%;
    justify-content: space-between;
    flex-direction: row;
    font-size: 1.3rem;
    font-weight: 700;
    gap: 10px;
    .language {
      flex-basis: 33.33%;
      width: 100%;
      display: flex;
      align-items: center;
      gap: 10px;
      font-size: 1.3rem;
      font-weight: 700;
    }
  }

  @media screen and (max-width: 992px) {
    .section {
      padding: 1rem;
    }
    .single-language {
      flex-basis: 50%;
      font-size: 1.2rem;
      font-weight: 800;
    }
    .languages {
      flex-direction: column;
    }
    .language {
      flex-basis: 100%;
    }
  }
`

export default Wrapper
