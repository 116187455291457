import Wrapper from './NavWrapper'
import { GiHamburgerMenu } from 'react-icons/gi'
import { useState } from 'react'
import { NavLink } from 'react-router-dom'
import { FaGithubSquare } from 'react-icons/fa'
import { HiOutlineMail } from 'react-icons/hi'
import { FiLinkedin } from 'react-icons/fi'
function Navbar() {
  const [showNavbar, setShowNavbar] = useState(false)
  return (
    <Wrapper>
      <div className='navbar-container'>
        <div className='navbar-row left'>
          <NavLink to='/' className='link'>
            Home
          </NavLink>
          <button
            className={showNavbar ? 'show-menu burger btn' : 'burger btn'}
            onClick={() => {
              setShowNavbar(!showNavbar)
            }}
          >
            <GiHamburgerMenu />
          </button>
        </div>
        <div className={showNavbar ? 'show burger-content' : 'burger-content'}>
          <div className='navbar-row mid'>
            <NavLink to='/Projects' className='link'>
              Projects
            </NavLink>
            <NavLink to='/CV' className='link'>
              CV
            </NavLink>
          </div>
          <div className='navbar-row right'>
            <div className='links-container'>
              <a href='mailto: kmatagne@gmail.com'>
                <HiOutlineMail />
              </a>
              <a href='http://www.github.com/codeGeno'>
                <FaGithubSquare />
              </a>
              {/* <a href='http://www.linkedin.com'>
                <FiLinkedin />
              </a> */}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default Navbar
