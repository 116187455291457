import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 1rem 0.5rem;
  .projects-section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }
  .box-container {
    display: flex;
    flex-basis: 50%;
  }
  .box {
    border-top-color: var(--primary-500);
    border-top-width: 5px;
    border-top-style: solid;
    margin: 1rem;
    padding: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
  }
  .project-name {
    display: flex;
    justify-content: center;
    color: var(--primary-500);
    font-weight: 700;
  }

  .languages-container {
    display: flex;
    justify-content: center;
  }
  .language {
    flex-basis: 33.33%;
    display: flex;
    justify-content: center;
  }
  .btn-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    gap: 10px;
    .btn {
      font-size: 2rem;
    }
  }
  .text-container {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media screen and (max-width: 992px) {
    padding: 0.2rem;
    .box-container {
      flex-basis: 100%;
    }
    .box {
      margin: 0.5rem;
    }
    .btn-container {
      .btn {
        font-size: 1.4rem;
      }
    }
  }
  .img-box {
    display: flex;
    width: auto;
    margin: 0.5rem 0;
    border-radius: 10px;
    overflow: hidden;
  }
  .project-img {
    width: 100%;
    height: auto;
  }
`

export default Wrapper
