import Wrapper from './SkillsWrapper'
import { languages } from '../../data'

function Skills() {
  return (
    <Wrapper
      initial='hidden'
      whileInView='visible'
      viewport={{ once: true }}
      transition={{ duration: 0.3 }}
      variants={{
        visible: { opacity: 1, x: 0 },
        hidden: { opacity: 0, x: -200 },
      }}
    >
      <div className='section'>
        <h1>Technical Skills</h1>
        <div>
          <div>
            {
              //<h2>Programming languages / Frameworks </h2>
            }
            <div className='languages-container'>
              {languages.map((language) => {
                const { name, icon, color } = language
                return (
                  <div className='single-language'>
                    <div style={{ color: `${color}` }}>{icon}</div>
                    <p>{name}</p>
                  </div>
                )
              })}
            </div>
          </div>
        </div>
      </div>
    </Wrapper>
  )
}
export default Skills
