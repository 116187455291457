import background from '../../assets/hero.png'
import styled from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  .hero-code {
    background-color: #ffffff;
    flex-basis: 33%;
    font-size: 0.7rem;
    font-weight: 800;
  }
  .hero-section {
    display: flex;
    justify-content: space-evenly;
    background-color: #ffffff;
    padding: 2rem;
  }
  .hero {
    .hero-text {
      height: 100%;
      padding: 3rem;
      background-color: #ffffff;
      h1 {
        font-size: 3.5rem;
        font-weight: 900;
        color: var(--primary-500);
      }
    }
  }
  .btn-container {
    display: flex;
    gap: 1rem;
  }
  .btn {
    font-size: 2rem;
  }
  @media (max-width: 992px) {
    .hero-section {
      display: flex;
      background-color: #ffffff;
      padding: 0.5rem;
    }
    .hero-code {
      background-color: #ffffff;
      flex-basis: 50%;
      font-size: 0.4rem;
      font-weight: 600;
      padding: 0.5rem;
      overflow: hidden;
    }
    .hero {
      .hero-text {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 0;
        background-color: #ffffff;

        h1 {
          text-align: center;
          font-size: 2rem;
        }
      }
    }
    .btn {
      font-size: 0.8rem;
      padding: 1rem 0.5rem;
    }
  }
  .btn-container {
    gap: 5px;
  }
`

export default Wrapper
